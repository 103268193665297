import {useRouter} from 'vue-router';
import {useCustomFetch} from '~/composables/customFetch.js';

export const fetchWithErrorHandling = async (url, opts = {}, lazy = false) => {
    const router = useRouter();

    const {data, error, status} = await useCustomFetch(url, opts, lazy);

    if (error.value) {
        if (error.value.statusCode === 410) {
            throw createError({statusCode: 410, statusMessage: 'Page Is Gone'});
        }

        if (error.value.statusCode === 405) {
            throw createError({statusCode: 404, statusMessage: 'Page Not Found'});
        }

        if (error.value.statusCode === 401) {
            await navigateTo(`/login?redirect=${router.currentRoute.value.fullPath}`);
            return;
        }

        if ([404, 403].includes(error.value.statusCode)) {
            throw createError({statusCode: 404, statusMessage: 'Page Not Found'});
        }

        throw createError({statusCode: 500, statusMessage: 'Internal Error'});
    }

    return data;
};
